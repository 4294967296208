import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/eron.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    
    <footer className="footer">
      <p>Disclaimer: The Church of Jesus Cat ($BELIEVE) is a memecoin created purely for entertainment purposes. Its price may go up or down. </p>
      
    </footer>
  )
}
