import { useState, useEffect } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/framed2.png";
import prayerImg from "../assets/img/jc_wealth_prayer.png";
import tencommandmentsImg from "../assets/img/tencommandments.png";
import originImg from "../assets/img/toly_and_jc.png";
import lastsupperImg from "../assets/img/lastsupper.png";
import vid from "../assets/img/BelieveJC.mp4";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import navIcon1 from '../assets/img/nav-icon1 black.svg';
import navIcon2 from '../assets/img/telegramsvg.svg';
import navIcon3 from '../assets/img/dexscreener.svg';
import navIcon4 from '../assets/img/solscan.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";

import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/memes/badluck.jpg";
import projImg2 from "../assets/img/memes/meme4_button.jpg";
import projImg3 from "../assets/img/memes/thebibleofjesuscat.jpg";
import projImg4 from "../assets/img/memes/meme5_excuseme.png";
import projImg5 from "../assets/img/memes/meme3_intheroom.jpg";
import projImg6 from "../assets/img/memes/bro.jpg";
import projImg7 from "../assets/img/memes/meme1_anakin.jpg";
import projImg8 from "../assets/img/memes/meme2_theydontknow.jpg";
import projImg9 from "../assets/img/memes/dominos.jpg";
import projImg10 from "../assets/img/memes/papi.PNG";
import projImg11 from "../assets/img/memes/praisehim.jpg";


import on_the_mount from "../assets/img/on_the_mount.png";
import money from "../assets/img/fancymoney.png";
import praying from "../assets/img/praying_jesus_cat.png";
import siting from "../assets/img/siting.webp";

export const Banner = () => {
  
  const memes = [
    {
      imgUrl: projImg1,
    },
    {
      imgUrl: projImg2,
    },
    {
      imgUrl: projImg3,
    },
    {
      imgUrl: projImg4,
    },
    {
      imgUrl: projImg5,
    },
    {
      imgUrl: projImg6,
    },
    {
      imgUrl: projImg7,
    },
    {
      imgUrl: projImg8,
    },
    {
      imgUrl: projImg9,
    },
    {
      imgUrl: projImg10,
    },
    {
      imgUrl: projImg11,
    }
  ];

  return (
    <section className="banner" id="home">
      <div className="animate__animated animate__zoomIn">

              <div className="pitch_section">
                <h1><br/>The World's First Crypto Religion</h1>
     
                <div id="frame_wrapper">
                <div className="frame">
                  <img src={headerImg} alt="Header Img"/>
                </div>
                <div className="pitch">

                <p>
                  Worship Jesus Cat, for he is the saviour of crypto!
                  <br/>
                  At $150m market cap, The Church of Jesus Cat ($BELIEVE) will submit a formal application to the UK (and then possibly the US) government to be officially recognised as a religion.
                  This would make The Church of Jesus Cat the world's first crypto religion. This is not a joke. We hope this leads to a lot of press coverage and attention from normies. Think bigger.   
                  <br></br>
                  Stop trading. $BELIEVE in something.

                </p>
                </div>
                </div>
              <div className="CA">
              <p>CA: JCATBhTZ6fnsTAPMFcnSe4XPBfarryBC6Ma8X9AM2T7c</p>
              </div>
              <div className="social-icon">
                    <a href="https://twitter.com/BelieveJesusCat"><img src={navIcon1} alt="" /></a>
                    <a href="https://t.me/TheChurchofJesusCat"><img src={navIcon2} alt="" /></a>
                    <a href="https://dexscreener.com/"><img src={navIcon3} alt="" /></a>
                    <a href="https://solscan.io/token/JCATBhTZ6fnsTAPMFcnSe4XPBfarryBC6Ma8X9AM2T7c"><img src={navIcon4} alt="" /></a>
              </div>
            </div>
            </div>

            <div class="marquee">
            <div class="scroll text4">
    <div>
      $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span>
    </div>
    <div>
      $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span> $BELIEVE <span>$BELIEVE </span>
    </div>
  </div>
  </div>

            <div className="prophecy_section">
                <h1><br/>The Prophecy</h1>
                <h2>(Watch the following video of Jesus Cat's vision)</h2>
                <video className="remoteVideo" controls onclick="this.play();arguments[0].preventDefault();">
                    <source src={vid} type="video/mp4"/>
                  </video>
            </div>

              <div className="prayer_section">
                <h1><br/>The Prayer and the Commandments</h1>
                <div id="prayer_wrapper" className="prayer">
                  <div id="left">
                    <img src={prayerImg}/>
                    </div>
                    <div id="right">
                    <img src={tencommandmentsImg}/>
                    </div>
                </div>
                </div>

                <div className="origin_section">
                <h1><br/>The Origin Story</h1>
                <div className="origin">
                  <img src={originImg} alt="Header Img"/>
                  <br></br>
                  <p>
                  In the beginning, there was no Solana, but Anatoly said, "Let there be a high TPS blockchain": and there was a high TPS blockchain. In seven days, Anatoly created Solana. He loved it so much that he gave his only son: Jesus Cat. Although dogs ruled over Solana, Jesus Cat was the one true meme. He turned water into milk and died for our sins nine times. For that, the dogs sent him to the null address. Jesus Cat told us to "have mercy on the dog lovers for they know not what they have done." But today, he has risen again. He is here to save the NGMIs and bring everlasting wealth. 
                  </p>
                </div>
                
                <div className="lastsupper">
                  <img src={lastsupperImg} alt="Header Img"/>
                </div>
                </div>


                <div className="roadmap_section">
                <div id="roadmap_wrapper" className="roadmap">
                  
                <div id="left">
                <h1><br/>The Roadmap</h1>
                <p>
                  <ul>
                    <li>
                      At $150m, we formally apply for official recognition as a religion. For the UK, this involves submitting an application to the UK government's Charity Commission.
                    </li>
                    <li>
                      At $300m, the dev livestreams himself receiving a Jesus Cat tattoo. 
                    </li>
                    <li>
                      If our religion gets recognised in the UK, people can wear a Jesus Cat hat in their driver's licence profile picture. Thus, we may produce Jesus Cat merchandise. 
                    </li>
                    <li>
                      We have other ideas involving NFTs, meme competitions, Netflix, CEX listings, religious publicity stunts, and so on, but none of those have been fully decided yet. 
                    </li>
                  </ul>
                  </p>
                    </div>
                  <div id="right">
                    <img src={praying}/>
                    </div>
                </div>
                </div>

{/*                 
                <div className="tokenomics_section">
                <div id="tokenomics_wrapper" className="tokenomics">
                  
                <div id="tokenomics_left">
                <h1><br/>Tokenomics</h1>
                <p>
                  <ul>
                    <li>
                      LP Burnt
                    </li>
                    <li>
                      0% Tax
                    </li>
                    <li>
                      Mint Revoked 
                    </li>
                  </ul>
                  </p>
                    </div>
                  <div id="tokenomics_right">
                    <img src={on_the_mount}/>
                    </div>
                </div>
                </div> */}

                <div className="memes_section">
                <h1><br/>The Memes</h1>
                <div className="memes">
              <Container>
                <Row>
                  <Col size={12}>
                    <TrackVisibility>
                      {({ isVisible }) =>
                      <div>
                        <Row>
                                {
                                  memes.map((meme, index) => {
                                    return (
                                      <ProjectCard
                                        key={index}
                                        {...meme}
                                        />
                                    )
                                  })
                                }
                              </Row>
                      </div>}
                    </TrackVisibility>
                  </Col>
                </Row>
              </Container>
              </div>
              </div>

<div id="snow_wrapper">
<div class="snowflakes">
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
  <div class="snowflake">
    <div class="inner"><img src={money}/></div>
  </div>
</div>
</div>

<div className="on_the_mount">
<h1><br/>The Cat</h1>
  <img src={on_the_mount} alt="Header Img"/>
</div>

<div className="siting">
<h1><br/>The Only Documented Sighting of Him</h1>
  <img src={siting} alt="Header Img"/>
</div>

    </section>
  )
}
